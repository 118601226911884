 
import { IonItemGroup, IonIcon, IonItem, IonItemDivider, IonLabel,
  IonPopover,
 } from '@ionic/vue';
import {   
  volumeMuteOutline,
  medkitOutline,
  constructOutline,
  peopleOutline,
  albumsOutline,
  telescopeOutline,
  wifiOutline,
  desktopOutline,
  contractOutline,
  syncOutline,
  trophyOutline,
  rocketOutline,
 } from 'ionicons/icons';

import Popver from './popover.vue'

// could use thumbnails or avatars in place of icons.
/*
https://ionicframework.com/docs/api/thumbnail
<ion-thumbnail>
    <img src="https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y">
  </ion-thumbnail>

https://ionicframework.com/docs/api/avatar

*/

import { defineComponent } from 'vue'; 

export default defineComponent({
  props: ['title','text',"date",'time','id','icon',"url"],
  components: {IonItemGroup, IonIcon, IonItem, IonItemDivider, IonLabel,
    },
  data () {
    return {
      popOpen: false,
    }
  },
  methods: {
    popState(v: boolean) {
      this.popOpen = v
      console.log("Close:",v)
    },
    getIcon(){
      //console.log("Icon id:",parseInt(this.icon))
      switch (parseInt(this.icon)) {
        case 1:
          return desktopOutline;
        case 2:
          return constructOutline;
        case 3:
          return peopleOutline;
        case 4:
          return rocketOutline;
        default:
          return telescopeOutline;
      } 
    }
  },
  setup() {
    return {
      volumeMuteOutline,
      medkitOutline,
      constructOutline,
      peopleOutline,
      albumsOutline,
      telescopeOutline,
      wifiOutline,
      desktopOutline,
      contractOutline,
      syncOutline,
      trophyOutline,
      rocketOutline,
    }
  }
});

