import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-af1cbe2c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "eventTitle" }
const _hoisted_2 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_item_divider = _resolveComponent("ion-item-divider")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_item_group = _resolveComponent("ion-item-group")!

  return (_openBlock(), _createBlock(_component_ion_item_group, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_item_divider),
      _createVNode(_component_ion_item, { color: "light" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_icon, {
            icon: _ctx.getIcon(),
            slot: "start",
            class: "eventIcon"
          }, null, 8, ["icon"]),
          _createVNode(_component_ion_label, {
            position: "inline",
            class: "eventDate"
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.title), 1),
              _createElementVNode("a", {
                class: "eventMore",
                href: _ctx.url,
                target: "_blank"
              }, "Zum Angebot", 8, _hoisted_2)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}