
import {IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonButton, 
IonIcon, IonImg, IonLabel,
IonCardSubtitle,
 } from '@ionic/vue';
import { defineComponent } from 'vue';



import { 
  shareSocialOutline,
  mailOutline,
 } from 'ionicons/icons';


export default defineComponent ({
  name: "IntroText",
  components: { IonCard, IonCardContent, IonCardHeader, IonCardTitle,  
     IonButton, IonIcon, IonImg, IonLabel,
     IonCardSubtitle,
  },
  setup() {
    const moreUrl = "https://lerninseln.com"
    return {
      moreUrl,
      mailOutline,shareSocialOutline,
    }
  },

});
