import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!

  return (_openBlock(), _createBlock(_component_ion_card, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_card_content, { class: "center" }, {
        default: _withCtx(() => [
          _createElementVNode("div", null, [
            _createVNode(_component_ion_button, {
              href: _ctx.imprintUrl,
              target: "_blank"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_icon, {
                  icon: _ctx.informationCircleOutline,
                  alt: "Impressum"
                }, null, 8, ["icon"]),
                _createVNode(_component_ion_label, null, {
                  default: _withCtx(() => [
                    _createTextVNode("Impressum")
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["href"]),
            _createVNode(_component_ion_button, {
              href: _ctx.gdprUrl,
              target: "_blank"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_icon, {
                  icon: _ctx.shieldHalfOutline,
                  alt: "Datenschutz"
                }, null, 8, ["icon"]),
                _createVNode(_component_ion_label, null, {
                  default: _withCtx(() => [
                    _createTextVNode("Datenschutz")
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["href"]),
            _createVNode(_component_ion_button, {
              href: _ctx.gitUrl,
              target: "_blank"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_icon, {
                  icon: _ctx.logoGithub,
                  alt: "Github"
                }, null, 8, ["icon"]),
                _createVNode(_component_ion_label, null, {
                  default: _withCtx(() => [
                    _createTextVNode("Quellcode")
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["href"])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}