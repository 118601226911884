
import {IonCheckbox, IonIcon, IonItemGroup, IonItem } from '@ionic/vue';

import { IonButton, IonPopover, popoverController } from '@ionic/vue';
import { defineComponent, ref } from 'vue';

import FilterInfo from "./FilterInfo.vue"


export default defineComponent ({
  name: "FilterItem",
  components: { IonCheckbox, IonIcon, IonItemGroup, IonItem, 
  },
  data () {
    return {
      checked: this.check,
    }
  },
  props: {
    "name": String,
    "info": String,
    "icon": IonIcon,
    "check": Boolean
  },
  emits: ['filter'],
  methods: {
    filter(){
      //console.log("Filter:", this.name," - ",this.check, this.checked, this.info)
      if (this.checked) {
        this.$emit("filter",true)
      } else {
        this.$emit("filter",false)
      }
    },
    async iconHelp(ev: Event) {
      console.log("help",ev)
      const popover = await popoverController
        .create({
          event: ev,
          reference:"trigger",
          trigger:"top-center",
          side:"top",
          alignment:"center",
          component: FilterInfo,
          componentProps: {"info":this.info},
          cssClass: 'filterInfoClass',
          translucent: true
        })
      await popover.present();
      const { role } = await popover.onDidDismiss();
      console.log('onDidDismiss resolved with role', role);
    },

  },
  
  watch: {
    "check": function (a,b) { 
        console.log(this.name," : ",a,b)
        this.checked = a
    }
  },

});
