
import {IonCard, IonCardContent, IonButton, IonIcon, IonLabel } from '@ionic/vue';
import { defineComponent } from 'vue';


import { 
  informationCircleOutline,
  shieldHalfOutline,
  logoGithub,
 } from 'ionicons/icons';


export default defineComponent ({
  name: "ImPrint",
  components: { IonCard, IonCardContent, IonLabel, 
     IonButton, IonIcon
  },
  methods: {
  },
  setup() {
    return {
      informationCircleOutline,shieldHalfOutline,logoGithub,
      gdprUrl:"https://www.karlsruhe.de/datenschutz",
      imprintUrl: "https://www.karlsruhe.de/impressum",
      gitUrl: "https://github.com/stadt-karlsruhe/lerninseln-pwa",
    }
  },

});
