
import {IonCard, IonCardContent, IonCardHeader,IonCardSubtitle,
      IonGrid, IonRow, IonCol } from '@ionic/vue';
//import { IonSpinner } from '@ionic/vue';
import { defineComponent, ref, toRef } from 'vue';

import FilterItem from '@/components/FilterItem.vue';

import { 
  volumeMuteOutline,
  medkitOutline,
  constructOutline,
  peopleOutline,
  albumsOutline,
  telescopeOutline,
  wifiOutline,
  desktopOutline,
  contractOutline,
  syncOutline,
  trophyOutline,
  rocketOutline,
 } from 'ionicons/icons';

const icons=[ 
  desktopOutline,
  constructOutline,
  peopleOutline,
  rocketOutline,
  telescopeOutline
  ]

const labels = ["F1","F2","F3","F4","F5"]
const infos = [
  "Teilhabe","MINT","3. Orte","Beteiligung","Wissen"
  ]

export default defineComponent ({
  name: "SingleFilter",
  components: { IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, 
  FilterItem,
  IonGrid, IonRow, IonCol,
  //IonSpinner,
  },
  props: ["reload"],
  watch: {
    rl(a,b) {
      //alert("Reload")
      console.log("Reload",a,b)
    },
  },
  data () {
    return {
      check: [false,false,false,false,false],
      update: 0,
      icons: icons,
      labels: labels,
      filterOff: true,
      infos: infos,
      isLoading: false
    }
  },
  methods: {
    async sync() {
      console.log("Sync")
      this.isLoading = true
      this.$emit("update")
      setTimeout(()=>{this.isLoading=false},2000)
    },
    async onFilter(x: number, y: boolean) {
        //console.log("On Filter")
        //console.log("Filter event: ",x,y,this.filter)
        if (y) {
          for (let i=0;i<this.check.length;i++){
            this.check[i] = (i == x)
            //console.log("Check on:",x)
          }
          this.filter = x + 1 // categories run from 1
        } else {
          if (this.check[x]) {
            this.filter = 0
            //console.log("Check off:",x)
          }
        }
        this.$emit("filter",this.filter) 

    },
  },
  setup(props) {
    const filter = ref(0)
    const rl = toRef(props, 'reload')
    return {
      filter,
      rl,
      volumeMuteOutline,
      medkitOutline,
      constructOutline,
      peopleOutline,
      albumsOutline,
      telescopeOutline,
      wifiOutline,
      desktopOutline,
      contractOutline,
      syncOutline,
      trophyOutline,
      rocketOutline,
    }
  },

});
