import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_checkbox = _resolveComponent("ion-checkbox")!
  const _component_ion_item_group = _resolveComponent("ion-item-group")!

  return (_openBlock(), _createBlock(_component_ion_item_group, { class: "filter" }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_item, { lines: "none" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_icon, {
            icon: _ctx.icon,
            class: "filterIcon",
            onClick: _ctx.iconHelp
          }, null, 8, ["icon", "onClick"])
        ]),
        _: 1
      }),
      _createVNode(_component_ion_item, {
        class: "filterToggle",
        lines: "none"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_checkbox, {
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => {{_ctx.checked = $event;_ctx.filter()}}),
            modelValue: _ctx.checked
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}